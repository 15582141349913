.footer-header__upper {
    padding-top: 53px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
}

.footer-header__lower {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 41px;
    text-align: center;
    color: #000000;
    padding-bottom: 10px;
}

@media screen and (max-width: 400px) {
    .footer-header__upper {
        font-size: 22px;
        line-height: 30px;
    }

    .footer-header__lower {
        width: 90%;
        margin: auto;
        font-size: 16px;
        line-height: 22px;
    }
}
