.footer-contact {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    padding-bottom: 36px;
    flex-wrap: wrap;
    padding-right: 20px;
    padding-left: 20px;
}

.footer-contact > .footer-contact__item:not(:first-child) {
    margin-left: 26px;
}

.footer-contact__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
}

.footer-contact__item > img {
    margin-right: 10px;
}

.footer-contact__item a {
    color: black;
    text-decoration: none;
}

@media screen and (max-width: 420px) {
    .footer-contact--ru {
        justify-content: flex-start;
    }

    .footer-contact--ru > .footer-contact__item {
        margin-left: 21px;
    } 
}

@media screen and (max-width: 400px) {
    .footer-contact {
        justify-content: flex-start;
        flex-wrap: nowrap;
        flex-direction: column;
        font-size: 10px;
        padding-left: 10px;
        background: rgba(196, 196, 196, 0.2);
    }

    .footer-contact > .footer-contact__item {
        margin-left: 21px;
    }  
    
    .footer-contact__item {
        margin-bottom: 10px;
        font-size: 10px;
    }
    
    .footer-contact__item > img {
        height: 18px;
        max-width: 20px;
    }

    .footer-contact__item a {
        color: black;
    }
}
