.recommend-item {
    padding: 0 10px 10px 10px;
    max-width: 410px;
    text-align: center;
    font-style: normal;
    font-size: 16px;
    color: #000;
    display: flex;
    align-items: center;
} 

.recommend-item__text {
    margin-left: 20px;
}

.recommend-item--right {
    padding-left: 100px;
}

@media screen and (max-width: 950px) {
    .recommend-item--right {
        padding-left: 50px;
    }
}

@media screen and (max-width: 900px) {
    .recommend-item--right {
        padding-left: 100px;
    }
}

@media screen and (max-width: 375px) {
    .recommend-item {
        padding-left: 20px;
    }

    .recommend-item--right {
        padding-left: 20px;
    }

    .recommend-item__text {
        text-align: left;
    }
}

@media screen and (max-width: 350px) {
    .recommend-item {
        flex-direction: column;
    }

    .recommend-item__text {
        margin-bottom: 20px;
    }

    .recommend-item__text {
        text-align: center;
    }
}
