.header-top {
    height: 67px;
    width: 100%;
    background-color: #F05A28;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 3;
    position: relative;
}

.header-top__link {
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    text-decoration: none;
}

.flag {
    height: 15px;
    vertical-align: middle;
}

.header-top-desktop .header-top__link:not(:first-child) {
    margin-left: 30px;
}

.header-top-desktop .header-top__link:last-child {
    margin-right: 95px;
}

.header-top-mobile {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 3;
}

.header-top-mobile > img {
    cursor: pointer;
}

.header-top-mobile__content {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #F05A28;
}

.header-top-mobile__close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
}

.header-top-mobile__content--open {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media screen and (max-width: 1090px) {
    .header-top-desktop {
        display: none;
    }

    .header-top-mobile {
        display: unset;
    }

    .header-top__link {
        color: white;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 20px;
    }
}
