.recommend-footer {
    width: 100%;
    max-width: 1130px;
    background: linear-gradient(180deg, #FAAC18 0%, #F05A28 100%);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-align: center;
    margin: auto;
    position: relative;
}

.recommend-footer__text {
    width: 75%;
    margin: auto;
    padding-bottom: 30px;
    position: relative;
    z-index: 2;
}

.recommend-footer__header{
    position: relative;
    width: 75%;
    text-transform: uppercase;
    margin: auto;
    padding-top: 30px;
    z-index: 2;
}

.recommend-footer__leaf {
    position: absolute;
    top: -45px;
    right: -30px;
    z-index: 1;
}

@media screen and (max-width: 1250px) {
    .recommend-footer {
        width: calc(100% - 60px);
    }

    .recommend-footer__text {
        width: 85%;
    }
}

@media screen and (max-width: 475px) {
    .recommend-footer {
        width: calc(100% - 40px);
    }

    .recommend-footer__leaf {
        width: 25%;
        top: -30px;
        right: -20px;
    }
    
    .recommend-footer__leaf > img{
        max-width: 100%;
    }
}

@media screen and (max-width: 375px) {
    .recommend-footer__header{
        width: 100%;
    }

    .recommend-footer__text {
        width: 92%;
    }

    .recommend-footer__leaf {
        width: unset;
    }
}
