.recommend-header__text {
    max-width: 50%;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
    color: #F05A28;
    margin: 35px auto 0 auto;
    text-align: center;
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 950px) {
    .recommend-header__text {
        max-width: 60%;
    }
}

@media screen and (max-width: 750px) {
    .recommend-header__text {
        max-width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .recommend-header__text {
        font-size: 22px;
        line-height: 30px;
        padding: 0 20px;
    }
}

@media screen and (max-width: 455px) {
    .recommend-header__text {
        max-width: 90%;
    }
}

@media screen and (max-width: 375px) {
    .recommend-header__text {
        max-width: 100%;
    }
}
